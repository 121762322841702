import React, { useContext, useEffect, useState } from "react";
import { Typography, Tooltip, Box, Select, Option, Button } from "@mui/joy";
import { axiosInstance } from "../../helpers/axiosConfig.js";
import { getUserID } from "../../helpers/AuthBarrier";
import { AlertContext } from "../../components/AlertFlag.js";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { convertTimeString } from "../../helpers/TimeConversionHelper";
import NavBlocker from "../../components/NavBlocker.js";
import { NoteTypeSelect } from "../../components/NoteTypeSelect.js";

function SettingsView() {
    const { addAlert } = useContext(AlertContext);
    const [ splitHPI, setSplitHPI ] = useState(true);
    const [ noteFormat, setNoteFormat] = useState('');
    const [ noteType, setNoteType ] = useState('');
    const [ noteViews, setNoteViews ] = useState([]);
    const [ updatedAt, setUpdatedAt] = useState('');
    const [ voiceLanguage, setVoiceLanguage ] = useState('en-US');
    const [ noteLanguage, setNoteLanguage ] = useState('en-US');

    const [initialState, setInitialState ] = useState({
        splitHPI: false,
        noteFormat: '',
        noteType: '',
        noteViews: [],
        voiceLanguage: '',
        noteLanguage: '',
    });
    const [ dirty, setDirty] = useState(false);

    const handleNoteType = (event, newValue) => {
        setNoteType(newValue);
    }

    const handleVoiceLanguage = (event, voiceInput) => {
        setVoiceLanguage(voiceInput);
    }

    const handleNoteLanguage = (event, noteLanguage) => {
        setNoteLanguage(noteLanguage);
    }

    const handleSplitHPI = (event) => {
        setSplitHPI(event.target.checked);
    }

    const handleNoteFormat = (event, newValue) => {
        setNoteFormat(newValue);
    }

    const handleNoteViews = (event, newViews) => {
        setNoteViews(newViews)
    }

    const getUserPreferences = async () => {
        let response;
        await axiosInstance.get(`/api/v1/user/${getUserID()}/preference`).then( retrieved => {
            response = retrieved.data;
            setSplitHPI(response['split_hpi']);
            setNoteFormat(response['note_format']);
            setNoteType(response['note_type']);
            setNoteViews(response['note_views']);
            setVoiceLanguage(response['input_language']);
            setNoteLanguage(response['output_language']);
            setUpdatedAt(convertTimeString(response['updated_at']));

            setInitialState({
                splitHPI: response['split_hpi'],
                noteFormat: response['note_format'],
                noteType: response['note_type'],
                noteViews: response['note_views'],
                voiceLanguage: response['input_language'],
                noteLanguage: response['output_language'],
            })
        }).catch(error => {
            addAlert("There was a problem retrieving your settings", "danger")
        })
    }

    useEffect(() => {
        getUserPreferences();
    }, [])

    useEffect(() => {
        if (splitHPI !== initialState['splitHPI'] || noteFormat !== initialState['noteFormat'] || noteType !== initialState['noteType'] ||  voiceLanguage !== initialState['voiceLanguage'] || noteLanguage !== initialState['noteLanguage']) { 
            setDirty(true);
        } else {
            setDirty(false);
        }
    }, [noteFormat, splitHPI, noteType, initialState]);

    const handleSavePreferences = async () => {
        if (noteViews.length <= 0) {
            addAlert("You need to select at least one note view!", "danger");
            return;
        }
        if (!noteType) {
            addAlert("You need to select a default note type!", "danger");
            return;
        }
        await axiosInstance.put(`/api/v1/user/${getUserID()}/preference`, {
            "split_hpi": splitHPI,
            "note_format": noteFormat,
            "note_type": noteType,
            "note_views": noteViews,
            "input_language": voiceLanguage,
            "output_language": noteLanguage,
        }).then( response => {
            addAlert("Settings saved!", 'success');

            setInitialState({
                splitHPI,
                noteFormat,
                noteType,
                noteViews, 
                voiceLanguage,
                noteLanguage,
            })
        }).catch(error => {
            addAlert("There was a problem saving your settings", 'danger');
        })
    }
    

    return (
        <Box id="outline-it" sx={{
            display: "flex", flexDirection: "column", gap: 2, border: "1px solid #ccc"
        }}>
            <NavBlocker dirty={dirty} />
            <Typography level='h2'>Settings</Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: {xs: 'column', lg: 'row'},
                    justifyContent: 'space-between',
                    gap: 2,
                    px: "1rem"
                }}
            >
                <NoteTypeSelect value={noteType}
                            disabled={false}
                            onChange={handleNoteType}
                            sx={{
                                width: {
                                    xs: "10rem", 
                                    sm: "10vw", 
                                    md: "20rem",  
                                    lg: "10vw"
                                },
                                ml: 2
                            }}
                            calmTitle={true}
                            noteViews={noteViews}
                            />
                <Box sx={{alignItems: 'center', gap: 2,  justifyContent: 'space-between'}}>
                    <Box
                        sx={{display: 'flex', alignItems: 'center', gap: 2, pb: '1rem'}}
                    >
                        <Typography width="30%">Note Views:</Typography>
                        <Box sx={{display: 'flex',  alignItems: 'center', gap: "1rem"}}>
                            <Select
                                value={noteViews}
                                multiple
                                onChange={handleNoteViews}
                                sx={{
                                    width: {
                                        xs: "10rem", 
                                        sm: "10vw", 
                                        md: "20rem", 
                                        lg: "10vw"
                                    },
                                }}
                            >
                                <Option value="pediatric">Pediatric</Option>
                                <Option value="therapy">Therapy</Option>
                                <Option value="radiology">Radiology</Option>
                                <Option value="emergency_response">Emergency Response</Option>
                                <Option value="rehab">Rehab</Option>
                                <Option value="sports_medicine">Sports Medicine</Option>
                                <Option value="physio">Physio</Option>
                                <Option value="general_notes">General Notes</Option>
                                <Option value="athletic">Athletic</Option>
                            </Select>
                            <Tooltip title={'Note Views allows you to configure which note types you use in your practice'}> 
                                <HelpOutlineIcon/> 
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box
                        sx={{display: 'flex', alignItems: 'center', gap: 2}}
                    >
                        <Typography width="30%">Note Format:</Typography>
                        <Box sx={{display: 'flex',  alignItems: 'center', gap: "1rem"}}>
                            <Select
                                value={noteFormat}
                                onChange={handleNoteFormat}
                                sx={{
                                    width: {
                                        xs: "10rem", 
                                        sm: "10vw", 
                                        md: "20rem", 
                                        lg: "10vw"
                                    },
                                }}
                            >
                                <Option value="default">Default</Option>
                                <Option value="paragraph">Paragraph</Option>
                                <Option value="bullet_points">Bullet Points</Option>
                            </Select>
                            <Tooltip title={'Note Format determines the formatting of the structured note.'}> 
                                <HelpOutlineIcon/> 
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{alignItems: 'center', gap: 2,  justifyContent: 'space-between'}}
                >
                    <Box sx={{display: 'flex',  alignItems: 'center', gap: "1rem", pb: '1rem'}}>
                        <Typography>Voice Language:</Typography>
                        <Select value={voiceLanguage} onChange={handleVoiceLanguage} sx={{width: '10rem'}}>
                            <Option value="nl">Dutch</Option>
                            <Option value="zh-CN"> Chinese(Mandarin, Simplified) </Option>
                            <Option value="zh-TW"> Chinese(Mandarin, Traditional) </Option>
                            <Option value="zh-HK"> Chinese(Cantonese, Simplified) </Option>
                            <Option value="en">English</Option>
                            <Option value="en-US">English(USA)</Option>
                            <Option value="fr">French</Option>
                            <Option value="fr-CA">French(Québecois)</Option>
                            <Option value="de">German</Option>
                            <Option value="ja">Japanese</Option>
                            <Option value="ko">Korean</Option>
                            <Option value="pl">Polish</Option>
                            <Option value="ru">Russian</Option>
                            <Option value="es">Spanish</Option>
                        </Select>
                    </Box>
                    <Box sx={{display: 'flex',  alignItems: 'center', gap: "1rem"}}>
                        <Typography pr={'5px'}>Note Language: </Typography>
                        <Select value={noteLanguage} onChange={handleNoteLanguage} sx={{width: '10rem'}}>
                            <Option value="nl">Dutch</Option>
                            <Option value="zh-CN"> Chinese(Mandarin, Simplified) </Option>
                            <Option value="zh-TW"> Chinese(Mandarin, Traditional) </Option>
                            <Option value="zh-HK"> Chinese(Cantonese, Simplified) </Option>
                            <Option value="en">English</Option>
                            <Option value="en-US">English(USA)</Option>
                            <Option value="fr">French</Option>
                            <Option value="fr-CA">French(Québecois)</Option>
                            <Option value="de">German</Option>
                            <Option value="ja">Japanese</Option>
                            <Option value="ko">Korean</Option>
                            <Option value="pl">Polish</Option>
                            <Option value="ru">Russian</Option>
                            <Option value="es">Spanish</Option>
                        </Select>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex', justifyContent: 'center', alignItems: 'center',
                }}>
                    <Typography>
                        Last updated at: {updatedAt}
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    alignItems: 'start',
                    gap: 2,
                    px: "1rem"
                }}
            >
                
                <Box sx={{ display: 'flex', width: '100%', justifyContent: { xs: 'center', md: 'start' }, }}>
                    <Button onClick={handleSavePreferences} size='lg' sx={{width: '10rem',}}>Save</Button>
                </Box>
            </Box>
        </Box>
    )
}

export default SettingsView;