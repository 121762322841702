import React, { useEffect } from "react";
import "../../styling/DefaultView.css";
import { useNavigate } from "react-router-dom";
import { Box, Button, Link, Typography } from "@mui/joy";
import LandingText from "../../components/joy/LandingText";
import { authGuard } from "../../helpers/AuthBarrier";


function DefaultView() {
    const navigate = useNavigate();

    useEffect(() => {
        const checkUser = async () => {
            if (await authGuard()) {
                navigate('/new-visit');
            }
        }
        checkUser();
    }, [])

    const goSignIn = () => {
        navigate("/login");
    }

    const goSignUp = () => {
        navigate("/signup");
    }
    
    return (<Box sx={{ display: "flex", flexGrow: 1, height: "100%", justifyContent: "center", }}>
        <Box sx={(theme) => ({
                margin: '25px', borderRadius: '30px', display: "flex", flexDirection: "column", boxShadow: theme.shadow.md, backgroundColor: 'rgb(255, 255, 255, 50%)', [theme.breakpoints.up('sm')]: {
                    width: '30%'
                }, [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    height: '70%',
                    alignSelf: 'center',
                }, justifyContent: "space-between", gap: 2, alignItems: "center"
            })}>
            <Box
                sx={{display: "flex", justifyContent: "center",  alignItems: "center", width: "100%", mt:"5rem"}}
            >
                <img src="/assets/logo.png" alt='Logo' style={{maxWidth: "80%"}}/>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    flexGrow: 1, 
                    gap: 2, 
                    width: "100%",
                }}
            >
                <Typography level="h1">Welcome!</Typography>
                <Button onClick={goSignIn} sx={{ fontSize: "1rem", padding: '10px', width: "85%"}}> Sign In </Button>
                <Button onClick={goSignUp} sx={{ fontSize: "1rem", padding: '10px', width: "85%"}}> Get Started </Button>
                <Link href="mailto:team@medxinsight.com"> Contact Us</Link>
            </Box>
        </Box>
    </Box>);
    }


export default DefaultView