import { Box, Button, IconButton } from "@mui/joy";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React, { useState } from "react";


function Pagination({ count, page, onChange }) {
    const [currentPage, setCurrentPage ] = useState(page);

    const handlePageChange = (pageSelect) => {
        setCurrentPage(pageSelect);
        onChange(pageSelect);
    }

    const renderPossiblePages = () => {
        const possiblePages = [];
        let startFrom = Math.max(1, currentPage - 2);
        let pushEnd = true;
        let pushStart = true;
        let endFrom = Math.min(currentPage + 2, count);

        if (endFrom - startFrom < 4) {
            if (startFrom === 1) {
                endFrom = Math.min(startFrom + 4, count);
            } else {
                startFrom = Math.max(endFrom - 4, 1);
            }
        }
        endFrom === count ? pushEnd = false : pushEnd = true;
        startFrom === 1 ? pushStart = false : pushStart = true;
        if (pushStart) {
            possiblePages.push(
                <MoreHorizIcon sx={{alignSelf: "center"}} fontSize="small" key="more-pages-left"/>
            );
        }
        for (let i = startFrom; i <= endFrom; i++) {
            possiblePages.push(
                <Button key={i} onClick={() => handlePageChange(i)} disabled={currentPage === i} sx={{paddingInline:"0.4rem", color: "black", backgroundColor: "transparent", ":hover": {backgroundColor: "#f1f3f4"}}}> {i} </Button>
            );
        }
        if (pushEnd) {
            possiblePages.push(
                <MoreHorizIcon sx={{alignSelf: "center"}} fontSize="small" key="more-pages-right"/>
            );
        }
        return possiblePages;
    }


    return <Box display="flex">
        <IconButton key="go-back" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} size="sm"> <ArrowBackIosNewIcon /> </IconButton>
        {renderPossiblePages()}
        <IconButton key="go-forward" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === count} size="sm"> <ArrowForwardIosIcon /> </IconButton>
    </Box>
}

export default Pagination;