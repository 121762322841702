import { Dropdown, MenuButton, IconButton, MenuItem, Menu } from "@mui/joy";
import MenuIcon from '@mui/icons-material/Menu';
import React from "react";
import { NavLink } from "react-router-dom";



function MobileMenuButton() {


    return (<Dropdown >
        <MenuButton slots={{ root: IconButton }} sx={{ width: '15%' }}>
            <MenuIcon/>
        </MenuButton>
        <Menu>
            <MenuItem component={NavLink} to='/new-visit'>
                Current Visit
            </MenuItem>
            <MenuItem component={NavLink} to='/account'>
                Settings
            </MenuItem>
            <MenuItem component={NavLink} to='/logout'>
                Sign Out
            </MenuItem>
        </Menu>
    </Dropdown>)
}

export default MobileMenuButton;