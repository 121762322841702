import { getUserID } from "./AuthBarrier";
import { axiosInstance } from "./axiosConfig";



export async function put_user_account(user_details) {
    try {
        const response = await axiosInstance.put(`/api/v1/user/${getUserID()}`, user_details)
    } catch (error) {
        console.log(error)
        throw new Error(error);
    }
}