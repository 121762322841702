import { Button, DialogActions, DialogContent, DialogTitle, FormControl, Modal, ModalClose, ModalDialog } from "@mui/joy";
import React, { useContext, useState, useEffect } from "react";
import FloatingLabelInput from "../joy/FloatingInput";
import { formatPhoneNumber } from "../../helpers/TextFormat";
import { AlertContext } from "../AlertFlag";
import { put_user_account } from "../../helpers/UserAccount";


export default function UpdateUserAccountModal({ user, open, onClose }) {
    const { addAlert } = useContext(AlertContext);
    const [ firstName, setFirstName ] = useState(user.full_name.split(" ")[0]);
    const [ lastName, setLastName ] = useState(user.full_name.split(" ")[1]);
    const [ phoneNumber, setPhoneNumber ] = useState(user.phone_number);

    const updateUser = async () => {
        const payload = {
            "full_name": `${firstName} ${lastName}`,
            "phone_number": phoneNumber
        }

        await put_user_account(payload).then(() => { addAlert("Updated!", "success")}).catch(() => { addAlert("Couldn't update account", "danger")});
    }

    return <Modal open={open} onClose={onClose} variant="soft">
        <ModalDialog>
            <ModalClose sx={{ width: "auto"}}/>
            <DialogTitle> Update account details: </DialogTitle>
            <DialogContent>  You can modify your personal details, such as your name, phone number. </DialogContent>
            <form onSubmit={ async (event) => {
                event.preventDefault();
                await updateUser();
            }}>
                <FormControl sx={{ gap: 2 }}>
                    <FloatingLabelInput required label="First Name" value={firstName} onChange={(event) => { 
                        setFirstName(event.target.value)}}/>
                    <FloatingLabelInput required label="Last Name" value={lastName} onChange={(event) => { 
                        setLastName(event.target.value)}}/>
                    <FloatingLabelInput required label="Phone Number" value={formatPhoneNumber(phoneNumber)} onChange={(event) => { 
                        setPhoneNumber(event.target.value)}}/>
                </FormControl>
                <DialogActions>
                    <Button type="submit"> Update </Button>
                </DialogActions>
            </form>
        </ModalDialog>
    </Modal>
}