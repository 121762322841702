import React from 'react';

class TimerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      secondsElapsed: 0,
      intervalId: null,
    }
    this.warned = false;
    this.allowed = false;
  }
  

  componentDidUpdate(prevProps) {
    if (this.props.start && !prevProps.start) {
      this.startTimer();
    } else if (!this.props.start && prevProps.start) {
      this.stopTimer();
    }
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  startTimer = () => {
    if (!this.state.intervalId) {
      const intervalId = setInterval(() => {
        if (this.state.secondsElapsed >= 20) {
          if (!this.allowed) {
            this.allowed = true;
            this.props.allowGeneration();
          }
        }
        if (this.state.secondsElapsed + 1 >= 9000) {
          this.props.limitReached();
        }
        if (this.state.secondsElapsed + 1 >= 8700 && !this.warned) {
          this.warned = true;
          this.props.raiseWarning();
        }
        this.setState(prevState => ({ secondsElapsed: prevState.secondsElapsed + 1 }));
      }, 1000);
      this.setState({ intervalId });
    }
  };

  stopTimer = () => {
    clearInterval(this.state.intervalId);
    this.setState({ intervalId: null });
  };

  formatTime = () => {
    const { secondsElapsed } = this.state;
    const hours = Math.floor(secondsElapsed / 3600);
    const minutes = Math.floor((secondsElapsed % 3600) / 60);
    const seconds = secondsElapsed % 60;

    return [hours, minutes, seconds]
      .map(val => val < 10 ? `0${val}` : val)
      .join(':');
  };

  render() {
    return <div style={{marginTop: '2px'}}>{this.formatTime()}</div>;
  }
}

export default TimerComponent;