import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, FormControl, FormHelperText, IconButton, Typography } from "@mui/joy";
import "../../styling/ResetPassword.css";
import { AlertContext } from "../../components/AlertFlag";
import { axiosInstance } from "../../helpers/axiosConfig.js";
import { broadcastEvent, getToken, getUserID } from "../../helpers/AuthBarrier";
import FloatingLabelInput from "../../components/joy/FloatingInput.js";
import VisibilityIcon from '@mui/icons-material/Visibility';

function ResetPasswordView () {
    const navigate = useNavigate();
    const { addAlert } = useContext(AlertContext);
    const [passwordValue, setPasswordValue] = useState('');
    const [confirmPasswordValue, setConfirmPasswordValue] = useState('');
    const [viewPassword, setViewPassword] = useState(false);
    const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
    
    const handlePasswordChange = (event) => {
        setPasswordValue(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPasswordValue(event.target.value);
    };

    const setViewPasswordChange = (value) => {
        setViewPassword(value);
    }

    const setViewConfirmPasswordChange = (value) => {
        setViewConfirmPassword(value);
    }

    const handleSubmit = async () => {
        const specialCharsRegex = /[^a-zA-Z0-9_]/;
        if (passwordValue !== confirmPasswordValue) {
            addAlert("Passwords are not matching!", "danger")
        } else if(passwordValue.length <= 8) {
            addAlert("Please ensure your password is at least 9 characters long.", 'danger')
        }else if(!specialCharsRegex.test(passwordValue)) {
            addAlert("Please ensure your password contains a special character.", 'danger')
        } else {
            await axiosInstance.put(`/api/v1/user/${getUserID()}`, {
                'password': passwordValue,
            }).then(response => {
                addAlert("Password changed! You will be automatically redirected in 5 seconds...", 'success')
                
                setTimeout( () => {
                    broadcastEvent('localStorageChange', { key: 'token', value: getToken()});
                    navigate("/new-visit");
                }, 5000);
            })
        }
    }

    return (    
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
            }}
        >
            <Box id="card-box" sx={(theme) => ({
                boxShadow: theme.shadow.md, 
                width: {xs: "80%", lg: "30%"}, 
            })}>
                <Box id="main-box" sx={{gap: 2}}>
                    <Typography level="h1" sx={{alignSelf: 'start'}}>New Password: </Typography>
                    <FormControl sx={{ width:"100%"}}>
                        <FormHelperText sx={{pb: '0.5rem'}}>Please create a new password before logging in</FormHelperText>
                        <FloatingLabelInput 
                            label="Password"
                            value={passwordValue} 
                            onChange={handlePasswordChange} 
                            type={viewPassword ? "text" : "password"} icon={<IconButton onClick={() => {setViewPasswordChange(!viewPassword)}}>
                                <VisibilityIcon/>
                            </IconButton>}/>
                        <Typography level="body-sm" sx={{textAlign: 'left', pl: '0.5rem', pt: "0.5rem"}}>
                            Ensure your password has:
                        </Typography>
                        <Typography component="li" level="body-sm" sx={{ textAlign: 'left', pl: '1rem', '&:before': { content: '"• "' } }}>
                            at least 9 characters
                        </Typography>
                        <Typography component="li" level="body-sm" sx={{ textAlign: 'left', pl: '1rem', '&:before': { content: '"• "'} }}>
                            a special character
                        </Typography>
                    </FormControl>
                    <Box sx={{ width:"100%"}}>
                        <FloatingLabelInput 
                            label="Confirm Password"
                            value={confirmPasswordValue} 
                            onChange={handleConfirmPasswordChange} 
                            type={viewConfirmPassword ? "text" : "password"} icon={<IconButton onClick={() => {setViewConfirmPasswordChange(!viewConfirmPassword)}}>
                                <VisibilityIcon/>
                            </IconButton>}/>
                    </Box>
                        <Button onClick={handleSubmit} sx={{ fontSize: "1rem", padding: '10px'}}>Change</Button>

                </Box>
            </Box>
        </Box>
    )
}

export default ResetPasswordView