import React from "react";
import '../styling/PastVisits.css'
import { convertTimeString } from "../helpers/TimeConversionHelper";
import { ListItem, ListItemButton, ListDivider, Box, Typography, LinearProgress } from "@mui/joy";
import { useNavigate } from "react-router-dom";

function PastVisitCard({ note, openVisit, selected}) {
  const navigate = useNavigate();

    const parseAndShow = (note) => {
        try {
            let analysis = note['user_edited_result']['value'];
            if (analysis.length <= 2) {
                analysis = note['generate_note']['generated_result']['value']
            }
            if (analysis.length > 45) {
                return `${analysis.slice(0, 45)}...`;
            } else {
                return `${analysis}`;
            }
        } catch(error) {
            console.log(error)
        }
    }

    const parseTitle = (title) => {
        if (title.length >= 30) {
            return `${title.slice(0, 30)}...`
        } else {
            return title
        }
    }

    const openSelectedVisit = async () => {
      openVisit(note['id']);
      navigate(`${note['id']}`);
    }


    return <>
        <ListItem key={note['id']} sx={{padding: '0px', width: '100%'}}>
            <ListItemButton {...(selected === note['id'] && {
                selected: true,
                color: 'neutral',
              })}
              sx={{p: 2, width: '100%', borderRadius: '0px', marginTop: '0px', marginBottom: '0px' }} onClick={openSelectedVisit} disabled={!note['generate_note']}>
                <Box sx={{ pl: 2, width: '100%'}}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: 0.5,
                  }}
                >
                  <Typography level="body-xs" textColor="text.tertiary">
                    {convertTimeString(note['created_at'])}
                  </Typography>
                </Box>
                <div>
                  <Typography level="title-md" sx={{ mb: 0.5 }}>
                    {parseTitle(note['title'])}
                  </Typography>
                  {note['generate_note'] ? <Typography level="body-sm">{parseAndShow(note)}</Typography> : <LinearProgress variant='indeterminate' sx={{width:'75%'}}/>}
                </div>
              </Box>
            </ListItemButton>
        </ListItem>
        <ListDivider sx={{ m: 0, width: '100%' }} />
        </>
}

export default PastVisitCard