import React, { useEffect, useState } from "react";
import { Box, Select, List, ListItem, Typography, Option } from "@mui/joy";


export function NoteTypeSelect({onChange, value, disabled, sx, calmTitle=null, noteViews=null}) {
    const [ sections, setSections ] = useState(null);

    useEffect(() => {
        setSections(noteViews);
    }, [noteViews])

    return <Box sx={{display: "flex", alignItems: "center"}}>
        {!disabled && !calmTitle && <h3>Note Type: </h3>}
        {calmTitle && <Typography> Note Type: </Typography>}
        {sections && <Select placeholder="Note Type" onChange={onChange} value={value} disabled={disabled} sx={sx}>
            <List>
                {(disabled ? true : sections.includes('pediatric') ? true : false) && <>
                    <ListItem>
                        <Typography level="body-xs" sx={{ textTransform: 'uppercase' }}> Pediatric </Typography>
                    </ListItem>
                    <Option value="developmental_consult"> Developmental Consultation</Option>
                    <Option value="medical_assessment"> Medical Assessment </Option>
                    <Option value="developmental_followup"> Developmental Follow-up</Option>
                    <Option value="adhd_letter"> ADHD Letter </Option>
                    <Option value="referrel_letter_short"> Referral Letter Short </Option>
                    <Option value="one_month_development_assessment"> 1 Month Development Assessment </Option>
                    <Option value="two_month_development_assessment"> 2 Month Development Assessment </Option>
                    <Option value="four_month_development_assessment"> 4 Month Development Assessment </Option>
                    <Option value="six_month_development_assessment"> 6 Month Development Assessment </Option>
                    <Option value="nine_month_development_assessment"> 9 Month Development Assessment </Option>
                    <Option value="twelve_month_development_assessment"> 12 Month Development Assessment </Option>
                    <Option value="autism_diagnostic_letter"> Autism Diagnostic Letter </Option>
                </>}

                {(disabled ? true : sections.includes('therapy') ? true : false) && <>
                    <ListItem>
                        <Typography level="body-xs" sx={{ textTransform: 'uppercase' }}> Therapy </Typography>
                    </ListItem>
                    <Option value="therapy_note"> Therapy Note</Option>
                    <Option value="therapy_case_note"> Therapy - Case Note</Option>
                    <Option value="therapy_intake_assessment_adult"> Therapy - Intake Assessment Adult</Option>
                    <Option value="therapy_intake_assessment_child"> Therapy - Intake Assessment Child</Option>
                    <Option value="therapy_family_remediation"> Therapy - Family Mediation Note</Option>
                    <Option value="therapy_parenting_plan"> Therapy - Parenting Plan Note</Option>
                    <Option value="therapy_marriage_counselling"> Therapy - Marriage Counselling Note</Option>
                    <Option value="therapy_cbt_dbt"> Therapy - CBT / DBT Note</Option>
                    <Option value="therapy_supervision_clinical_consult"> Therapy - Supervision / Clinical Consult </Option>
                </>}

                {(disabled ? true : sections.includes('radiology') ? true : false) && <>
                    <ListItem>
                        <Typography level="body-xs" sx={{ textTransform: 'uppercase' }}> Radiology </Typography>
                    </ListItem>
                    <Option value="radiology"> Radiology</Option>
                </>}

                {(disabled ? true : sections.includes('emergency_response') ? true : false) && <>
                    <ListItem>
                        <Typography level="body-xs" sx={{ textTransform: 'uppercase' }}> Emergency Response</Typography>
                    </ListItem>
                    <Option value="emergency_response"> Emergency Response</Option>
                </>}

                {(disabled ? true : sections.includes('rehab') ? true : false) && <>
                    <ListItem>
                        <Typography level="body-xs" sx={{ textTransform: 'uppercase' }}> Rehab </Typography>
                    </ListItem>
                    <Option value="pace_rehab_treatment_note"> PACE - Rehab Treatment Note </Option>
                </>}

                {(disabled ? true : sections.includes('sports_medicine') ? true : false) && <>
                    <ListItem>
                        <Typography level="body-xs" sx={{ textTransform: 'uppercase' }}> Sports Medicine </Typography>
                    </ListItem>
                    <Option value="sports_medicine_consult_letter">Sports Medical Consult Letter</Option>
                    <Option value="sports_clearance">Sports Clearance Form</Option>
                    <Option value="sports_medicine">Sports Medical Note</Option>
                </>}
                    
                {(disabled ? true : sections.includes('physio') ? true : false) && <>
                    <ListItem>
                        <Typography level="body-xs" sx={{ textTransform: 'uppercase' }}> Physio </Typography>
                    </ListItem>
                    <Option value="physio">Physiotherapy Note</Option>
                    <Option value="physio_assessment_letter"> Physiotherapy Assessment Letter</Option>
                    <Option value="physio_soap_note">Physiotherapy SOAP Note</Option>
                    <Option value="chiropractic">Chiropractic Note</Option>
                    <Option value="chiro_referral_letter"> Chiro Referral Letter </Option>
                    <Option value="chiro_sate_note"> Chiro SATE Note </Option>
                    <Option value="chiropractic_soap"> Chiropractic SOAP Note</Option>
                </>}
                
                {(disabled ? true : sections.includes('general_notes') ? true : false) && <>
                    <ListItem>
                        <Typography level="body-xs" sx={{ textTransform: 'uppercase' }}> General Notes </Typography>
                    </ListItem>
                    <Option value="consult_note">Consult Note</Option>
                    <Option value="consult_note_condensed"> Consult Note Condensed</Option>
                    <Option value="discharge_exit_summary"> Discharge Exit Summary </Option>
                    <Option value="prescription_note"> Prescription Note</Option>
                    <Option value="progress_soap_note"> Progress SOAP Note</Option>
                    <Option value="patient_summary">Patient Summary</Option>
                    <Option value="referral_letter">Referral Letter</Option>
                    <Option value="patient_handout">Patient Handout</Option>
                    <Option value="consult_note_multiple_problems"> Consult Note Multiple Problems </Option>
                </>}

                {(disabled ? true : sections.includes('athletic') ? true : false) && <>
                <ListItem>
                    <Typography level="body-xs" sx={{ textTransform: 'uppercase' }}> Athletic </Typography>
                </ListItem>
                <Option value="athletic">Athletic Note</Option>
                <Option value="at_pt_soap"> AT PT SOAP Note</Option>
                <Option value="at_pt_treatment"> AT PT Treatment </Option>
                <Option value="at_pt_assessment"> AT PT Assessment </Option>
                </>}
            </List>
        </Select>}
    </Box>
}