
import jsPDF from "jspdf";
import { convertTimeString } from "./TimeConversionHelper";

export function downloadTextoTxt(soapNote, noteName, createdAt) {
    const blob = new Blob([`Visit at: ${convertTimeString(createdAt)} \n\n`, soapNote], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${noteName}.txt`;
    link.click();
    URL.revokeObjectURL(url);
}


export function downloadText(soapNote, noteName, createdAt) {
    const doc = new jsPDF()
    // Add logo
    doc.addImage("/assets/pdflogo.png", 'PNG', 4, 5);
    doc.setFont('helvetica', 'italic');
    doc.text(convertTimeString(createdAt), 150, 20);

    doc.setFont('helvetica', 'normal')
    doc.setFontSize(11);

    const fullNote = soapNote
    let appendLine = '';
    let textOffset = 40;
    let lineChars = 0;
    let i = 0;
    while(i < fullNote.length) {
        if (textOffset > 260) {
            doc.addPage()
            textOffset = 20;
        }
        if (fullNote[i] === '\n') {
            lineChars = 0;
            doc.text(appendLine, 7, textOffset);
            textOffset += 5;
            appendLine = '';
            i += 1;
            continue;
        } else if (lineChars > 90) {
            // Search for the next space in 30 chars
            let changeI = 0;
            for (let space = i; space < i + 30; space++) {
                if (fullNote[space] === " " || fullNote[space] === '\n') {
                    changeI += 1
                    break;
                } else {
                    appendLine += fullNote[space]
                    changeI += 1
                }
            }
            i += changeI;
            doc.text(appendLine, 7, textOffset);
            textOffset += 5;
            appendLine = '';
            lineChars = 0;
            continue;
        }
        appendLine += fullNote[i]
        lineChars += 1
        i += 1;
    }

    doc.text(appendLine, 7, textOffset)

    doc.save(noteName)
}


export function downloadJsonText(soapNote, noteName) {
    const doc = new jsPDF()
    // Add logo
    doc.addImage("/assets/pdflogo.png", 'PNG', 5, 5);

    // Add soapNote
    let jsonSoapNote;
    let soapNoteFormat;
    try {
        jsonSoapNote = JSON.parse(soapNote['user_edited_result']['value']);
        soapNoteFormat = soapNote['generate_note']['note_format']
    } catch {
        try {
            jsonSoapNote = JSON.parse(soapNote['generated_result']['value']);
            soapNoteFormat = soapNote['note_format']
        } catch {
            alert("Unexpected Download Error");
            return;
        }
    }
    let textOffset = 30;

    // Go through each key and add it as a header and then the corresponding text
    Object.entries(jsonSoapNote).forEach( ([key, value]) => {
        const formattedKey = key.replace(/_/g, ' ');
        doc.setFont('helvetica', 'bold')
        doc.setFontSize(16);
        doc.text(formattedKey, 10, textOffset)
        textOffset += 10;
        doc.setFont('helvetica', 'normal')
        doc.setFontSize(12);
        // Logic for paragraph format
        if (value.length > 0) {
            if (soapNoteFormat === 'default' || soapNoteFormat === 'paragraph') {
                //Remove all instances of \n
                let failSafe;
                if (value.length > 1) {
                    failSafe = value.join(". ")
                } else {
                    failSafe = value[0];
                }
                const retrieveText = failSafe.replace(/\n/g, "")
                let sliceString = 0
                let slicePerLine = 90;
                if (retrieveText.length < 90) {
                    doc.text(retrieveText.slice(sliceString, sliceString + 90), 12, textOffset);
                    textOffset += 5;
                }
                else {
                    while (sliceString < retrieveText.length) {
                        if (textOffset > 270) {
                            textOffset = 20;
                            doc.addPage()
                        }
                        slicePerLine = (retrieveText.slice(sliceString, sliceString + 90)).lastIndexOf(" ");
                        if(slicePerLine === 0 || sliceString + 90 > retrieveText.length) {
                            doc.text(retrieveText.slice(sliceString, sliceString + 90), 12, textOffset);
                            textOffset += 5;
                            break;
                        } else {
                            doc.text(retrieveText.slice(sliceString, sliceString+slicePerLine), 12, textOffset);
                        }
                        sliceString += slicePerLine + 1;
                        textOffset += 5;
                    }
                }
            } else {
                // Add bullet point logic
                for (let index = 0; index < value.length; index++) {
                    doc.text(`\u2022  ${value[index]}`, 15, textOffset);
                    textOffset += 5
                }
            }
        }
        textOffset += 10;
    })

    doc.save(noteName)
}
