import { useContext, useEffect, useState } from "react";
import "../styling/NavigationBar.css";
import { axiosInstance } from "../helpers/axiosConfig";
import { Button, Typography } from "@mui/joy";
import { AlertContext } from "../components/AlertFlag";


function HelpComponent() {
    const { addAlert } = useContext(AlertContext); 
    const [ userTier, setUserTier ] = useState(null);
    const [ userCapacity, setUserCapacity ] = useState(null);
    const [ userCurrent, setUserCurrent ] = useState(null);

    const getUserTier = async () => {
        try {
            await axiosInstance.get(`/api/v1/subscription`).then((response) => {
                setUserTier(response.data['tier']);
                if (response.data['tier'] === 'free') {
                    getUserCapacity();
                }
            }).catch(error => {
                console.log(error);
            })
        } catch (error) {
            throw Error("Couldn't fetch user tier")
        }
    }

    const getUserCapacity = async () => {
        try {
            await axiosInstance.get(`/api/v1/visit/capacity`).then((response) => {
                setUserCapacity(response.data['max_count']);
                setUserCurrent(response.data['created_count']);
            })
        } catch (error) {
            throw Error("Couldn't fetch user capcity")
        }
    }

    const upgradePlan = async () => {
        await axiosInstance.post(`/api/v1/subscription_paid`, {
            "tier": "premium"
        }).then( response => {
            const url = response.data.checkout_url
            window.location.href = url;

            addAlert("Plan saved!", "success")
        }).catch(error => {
            addAlert("There was a problem changing your plan", "danger")
        })
    }

    useEffect( () => {
        getUserTier();
    }, [addAlert])

    return (<div id="help-section">
        <div id="background-color">
            {userTier === 'free' ? <>
                <Typography sx={{textAlign: "center"}}> You have {userCapacity - userCurrent} notes left this month. Upgrade to get more!</Typography>
                <Button onClick={upgradePlan} sx={{ padding: "5px", width: "75%", backgroundColor: "#2a3041", alignSelf: "center"}}> UPGRADE </Button>
            </>
            : <><h3> Help Desk</h3>
            <p>Tutorial? Watch <b/> <a href="https://www.youtube.com/watch?v=3XgemXhqB6A" target="_blank" rel="noopener noreferrer"> here </a> </p>
            <p>Need help? Reach us <b/> <a href="mailto:team@medxinsight.com" target="_blank" rel="noopener noreferrer"> here </a></p> </>}
        </div>
      </div>)
}

export default HelpComponent