import React from 'react';
import { Sheet } from '@mui/joy';
import '../styling/ComponentStyling.css';



function AdaptResize({ children }) {
    return (
        <>
            {/* smUp */}
            <Sheet sx={{ display: { xs:"none", sm:"block"}, background: "transparent" }}>
                { children }
            </Sheet>
            {/* smDown? */}
            <Sheet sx={{ display: { xs:"block", sm:"none"}, background: "transparent" }}>
                <div id="take-screen">
                    <h3 id="center-title"> This view can only be rendered on desktop. </h3>
                </div>
            </Sheet>
        </>
    )
}

export default AdaptResize