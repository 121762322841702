import axios from "axios";
import { axiosInstance } from "./axiosConfig";

const S3_TIMEOUT = 60000;

export async function getPresignedURL() {
    try {
        const response = await axiosInstance.get('/api/v1/upload/url');
        return response['data'];
    } catch (error) {
        throw new Error('Unable to fetch presigned URL')
    }
}


export async function putAudioIntoPresigned(presignedFields, audioBlob) {
    const formData = new FormData();

    Object.keys(presignedFields['fields']).forEach(key => {
        formData.append(key, presignedFields['fields'][key]);
    })

    formData.append('file', audioBlob, '.webm');

    try {
        const response = await axios.post( presignedFields.url, formData, {
            timeout: S3_TIMEOUT
        })
        if (!response) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        console.log('File successfully uploaded');

        return response;
    } catch (error) {
        throw new Error(`HTTP error! status: ${error}`);
    }
}