import { Typography } from "@mui/joy";


export function HyperLink({ link, text }) {

    return <Typography
    component="a"
    href={link}
    target="_blank"
    rel="noopener noreferrer"
    sx={{
      color: 'primary.500', // Use theme primary color
      textDecoration: 'underline',
      fontWeight: '500',
      transition: 'color 0.2s ease-in-out',
      '&:hover': {
        color: 'primary.700', // Darker shade on hover
        textDecoration: 'none', // Optional hover effect
      },
      '&:visited': {
        color: 'neutral.500', // Style for visited links
      },
      '&:focus': {
        outline: '2px dashed', // Accessibility focus indicator
        outlineOffset: '2px',
      },
    }}>
        {text}
    </Typography>
}