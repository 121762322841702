// Not actually the main layout lmao, it's the navbar
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Sheet, List, ListItem, ListItemButton, ListItemDecorator, ListItemContent, AspectRatio } from '@mui/joy';
import MicIcon from '@mui/icons-material/Mic';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import HistoryIcon from '@mui/icons-material/History';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import HelpComponent from './HelpElement';
import StartIcon from '@mui/icons-material/Start';


import '../styling/NavigationBar.css';

function MainLayout() {
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  }

  return (
    <div id="nav">
      <Sheet sx={{ display: { xs:"none", sm:"flex"}, flexDirection: "column", flexGrow: 1, borderRight: "2px solid #f1f3f4", width: "250px", overflowY: "scroll"}}>
        <AspectRatio objectFit='contain' sx={{ padding: '5px', paddingLeft: "10px", paddingRight: "10px", backgroundColor:'inherit' }} variant='plain'>
          <img src="/assets/backgroundlogo.png" alt='Logo' className='nav-logo'/>
        </AspectRatio>
        <List component="nav" sx={{ backgroundColor: 'primary.main', padding: '10px' }}>
          <ListItem>
            <ListItemButton component={NavLink} to="/new-visit" sx={{ backgroundColor: isActive('/new-visit') ? '#f1f3f4' : 'inherit' }}>
              <ListItemDecorator> <MicIcon color={isActive('/new-visit') ? '600' : 'inherit'} fontSize='xl3'/></ListItemDecorator>
              <ListItemContent sx={{ fontSize: '13pt', fontWeight: isActive('/new-visit') ? '1000' : 'inherit' }}> Current Visit </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton component={NavLink} to="/prerecorded-visit" sx={{ backgroundColor: isActive('/prerecorded-visit') ? '#f1f3f4' : 'inherit' }}>
              <ListItemDecorator> <UploadFileIcon color={isActive('/prerecorded-visit') ? '600' : 'inherit'} fontSize='xl3'/></ListItemDecorator>
              <ListItemContent sx={{ fontSize: '13pt', fontWeight: isActive('/prerecorded-visit') ? '1000' : 'inherit' }}> Upload Visit </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton component={NavLink} to="/past-visits" sx={{ backgroundColor: isActive('/past-visits') ? '#f1f3f4' : 'inherit' }}>
              <ListItemDecorator> <HistoryIcon color={isActive('/past-visits') ? '600' : 'inherit'} fontSize='xl3'/></ListItemDecorator>
              <ListItemContent sx={{ fontSize: '13pt', fontWeight: isActive('/past-visits') ? '1000' : 'inherit' }}> Past Visits </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton component={NavLink} to="/account" sx={{ backgroundColor: isActive('/account') ? '#f1f3f4' : 'inherit' }}>
              <ListItemDecorator> <SettingsIcon color={isActive('/account') ? '600' : 'inherit'} fontSize='xl3'/></ListItemDecorator>
              <ListItemContent sx={{ fontSize: '13pt', fontWeight: isActive('/account') ? '1000' : 'inherit' }}> Settings </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton component={NavLink} to="/logout" sx={{ backgroundColor: isActive('/logout') ? '#f1f3f4' : 'inherit' }}>
              <ListItemDecorator> <LogoutIcon color={isActive('/logout') ? '600' : 'inherit'} fontSize='xl3'/></ListItemDecorator>
              <ListItemContent sx={{ fontSize: '13pt', fontWeight: isActive('/logout') ? '1000' : 'inherit' }}> Sign Out </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton component={NavLink} to="/new-visit-auto" sx={{ backgroundColor: '#f7f9feff', border: '1px solid #36498a', borderRadius: '6px' }}>
              <ListItemDecorator> <StartIcon color='#36498a' fontSize='xl2'/></ListItemDecorator>
              <ListItemContent sx={{ fontSize: '13pt', fontWeight: '1000' }}> Live Visit </ListItemContent>
            </ListItemButton>
          </ListItem>

        </List>
        <HelpComponent/>
      </Sheet>
    </div>
  );
}

export default MainLayout;