const { v4: uuidv4 } = require('uuid');


// Opens IndexedDB database
export function openDB() {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open("audioDB", 1);
        
        request.onupgradeneeded = function(event) {
            const db = event.target.result;
            db.createObjectStore("audioStore");
        };

        request.onsuccess = function(event) {
            resolve(event.target.result);
        };

        request.onerror = function(event) {
            reject("Failed to open IndexedDB: " + event.target.errorCode);
        };
    });
}

// Saves an audio blob to IndexedDB
export async function saveAudioLocally(blob, state, userPreferences) {
    const db = await openDB();
    const key = uuidv4();

    return new Promise((resolve, reject) => {
        const transaction = db.transaction("audioStore", "readwrite");
        const store = transaction.objectStore("audioStore");
        const request = store.put({key, blob, state, userPreferences}, key);

        request.onsuccess = function() {
            resolve(key);
        };

        request.onerror = function() {
            reject("Error saving audio locally.");
        };
    });
}

// Retrieves the audio blob from IndexedDB
export async function getAudioLocally() {
    const db = await openDB();
    return new Promise((resolve, reject) => {
        const transaction = db.transaction("audioStore", "readonly");
        const store = transaction.objectStore("audioStore");
        const request = store.getAll();

        request.onsuccess = function(event) {
            if (event.target.result) {
                resolve(event.target.result);
            } else {
                reject("No audio found.");
            }
        };

        request.onerror = function() {
            reject("Error retrieving audio.");
        };
    });
}

// Deletes the audio blob from IndexedDB
export async function deleteAudioLocally(key) {
    const db = await openDB();
    return new Promise((resolve, reject) => {
        const transaction = db.transaction("audioStore", "readwrite");
        const store = transaction.objectStore("audioStore");
        const request = store.delete(key);

        request.onsuccess = function() {
            resolve("Audio deleted from local storage.");
        };

        request.onerror = function() {
            reject("Error deleting audio locally.");
        };
    });
}

export async function clearAllAudio() {
    const db = await openDB();
    return new Promise((resolve, reject) => {
        const transaction = db.transaction("audioStore", "readwrite");
        const store = transaction.objectStore("audioStore");
        const request = store.clear();  // Clears all records in audioStore

        request.onsuccess = function() {
            resolve("All audio files deleted from local storage.");
        };

        request.onerror = function() {
            reject("Error deleting all audio files locally.");
        };
    });
}